























import Vue from "vue";
import { mapMutations } from "vuex";
import { USER_STATE } from "@/store/modules/auth/constants";
import { UserState } from "@/store/modules/auth/interfaces";
import router from "@/router";

export default Vue.extend({
  name: "ResetPasswordSuccess",
  data() {
    return {
      success_animation: require("@/assets/illustrations/onboarding-success.json")
    };
  },
  async created() {
    this.success_animation = JSON.stringify(this.success_animation);
  },
  methods: {
    ...mapMutations("auth", {
      set_user_state: USER_STATE
    }),
    async navigate_to_login() {
      this.set_user_state(UserState.UNAUTHENTICATED);
      await router.push("/auth/login");
    }
  }
});
